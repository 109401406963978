const bookingScope = () => {
  let $datepicker;
  let pricingModels;
  let specialOffers;
  let propertiesAvailabilities = {};
  let calculatedPrice;
  let selectedPricingModel;

  const isFrench = window.location.href.indexOf("/fr/") !== -1;
  const isSummer = window.location.href.indexOf(isFrench ? "/ete/" : "/summer/") !== -1;

  const englishTerms = {
    checkOurWinterWebsite: "check our winter website",
    checkOurSummerWebsite: "check our winter website",
    availabilityNotDefined: "availability not defined: ",
    enterYourHolidayDetails: "Enter your holiday details to book now",
    nights: "Nights",
    nightsLower: "nights",
    included: "Included",
    notIncluded: "Not included",
    cateringIsNotIncluded: "Catering is NOT included",
    endOfStayInc: "End-of-stay cleaning included",
    endOfStayEx: "End-of-stay cleaning NOT included",
    cateringFunc: () => {
      return `${getNightsDropdown().val()} days catering (including evening meals ${selectedPricingModel.cateringNightsOff
        ? "on " +
        (getNightsDropdown().val() -
          selectedPricingModel.cateringNightsOff) +
        " nights"
        : "every night"
        })`
    },
    annexRoom: "Annex room",
    annexRooms: "Annex rooms",
  };

  const frenchTerms = {
    checkOurWinterWebsite: "Consultez notre site web d'hiver",
    checkOurSummerWebsite: "Consultez notre site web été",
    availabilityNotDefined: "Disponibilité non définie",
    enterYourHolidayDetails: "Entrez vos détails de vacances pour réserver maintenant",
    nights: "Nuits",
    nightsLower: "nuits",
    included: "inclus",
    notIncluded: "non inclus",
    cateringIsNotIncluded: "La restauration NON inclus",
    endOfStayInc: "Ménage de fin de séjour inclus",
    endOfStayEx: "Ménage de fin de séjour NON inclus",
    cateringFunc: () => {
      return `${getNightsDropdown().val()} jours de restauration (comprenant les repas du soir sur ${selectedPricingModel.cateringNightsOff
        ?
        (getNightsDropdown().val() -
          selectedPricingModel.cateringNightsOff) +
        " nuits"
        : "toutes les nuits"
        })`
    },
    annexRoom: "Chambre annexe",
    annexRooms: "chambres annexes"
  };

  let terms = !isFrench ? englishTerms : frenchTerms;

  //const defaultDuration = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const getSelectedChaletName = () => {
    return $("input:radio[name=propertySelection]:checked, select[id=propertySelectionList]").val();
  };

  const getSelectedChaletItem = () => {
    return $("input:radio[name=propertySelection]:checked, select[id=propertySelectionList] option:selected");
  };
  const getChaletSelector = () => {
    return $("input:radio[name=propertySelection], select[id=propertySelectionList]");
  };

  const getNightsDropdown = () => {
    return $("select#duration");
  }

  const getAnnexCheckbox = () => {
    return $("#chkAnnex");
  }

  const getAnnexLi = () => {
    return getAnnexCheckbox().closest("li");
  }

  const getCleaningCheckbox = () => {
    return $("#chkCleaning");
  }

  const getCleaningLi = () => {
    return getCleaningCheckbox().closest("li");
  }

  const getCateringCheckbox = () => {
    return $("#chkCatering");
  }
  const getCateringLi = () => {
    return $("#chkCatering").closest("li");
  }

  const getFlexiPlusCheckbox = () => {
    return $("#chkFlexiPlus");
  }

  const getJsonDataFor = (fileName) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        dataType: "json",
        url: `https://www.chalets-lesgets.com/api/public-files/${fileName}.json`,
      })
        .then((response) => {
          resolve(response);
        })
        .fail(() => {
          console.log(`failed to load ${fileName}`);
          $("#dvAvailabilities").hide();
          $("#pErrorAvailabilities").show();
          reject();
        })
        ;
    });
  };

  const getAvailabilityFor = (fileName) => {
    return new Promise((resolve) => {
      if (!propertiesAvailabilities[fileName]) {
        // need to show a loading icon beside chalet picker
        getJsonDataFor(fileName)
          .then((availabilities) => {
            propertiesAvailabilities[fileName] = availabilities;
            resolve(availabilities);
          })
          .catch(() => {
            $("#dvLoading").hide();
          });
      }
      else
        resolve(propertiesAvailabilities[fileName]);
    });

  }

  const getFirstAvailableDate = (availabilities) => {
    const currentDateValue = new Date().valueOf() / Math.pow(10, 5);
    for (let index = 0; index < availabilities.length; index++) {
      const element = availabilities[index];
      const seasonText = isSummer ? "Summer" : "Winter";
      if (element.accomAvailable === "Available" && element.arrivalsAllowed && element.season === seasonText && element.dateValue > currentDateValue) {
        return new Date(element.date);
      }
    }
    return null;
  };

  const selectedDateChanged = (year, month, day, availabilities) => {
    const $dropdown = getNightsDropdown();

    const previousSelectedNights = $dropdown.val();

    $dropdown.empty();

    if (year == undefined || month == undefined || day == undefined) {
      setPrice(null);
      return;
    }

    // for each date in the sheet we associate a number (dateValue) which represents that date, this is easier for sorting and searching
    let dateValue = Date.UTC(year, month, day).valueOf() / Math.pow(10, 5);

    // find the availability record
    let index = binarySearchDateAvailability(
      availabilities,
      dateValue,
      0,
      availabilities.length - 1
    );

    if (index === -1) {
      setPrice(null);
      return;
    }

    // if the record is for another season, show the modal
    if ((isSummer && availabilities[index].season === "Winter")
      ||
      (!isSummer && availabilities[index].season === "Summer")) {
      openModal("winterModal");
      $datepicker?.datepicker("setDate", undefined);
      selectedDateChanged(undefined);
      return;
    }

    // if the selected date is not valid to be selected (but for some reason selected), unselect that.
    if (availabilities[index].accomAvailable !== "Available" || !availabilities[index].arrivalsAllowed) {
      $datepicker?.datepicker("setDate", undefined);
      selectedDateChanged(undefined);
      return;
    }

    let duration;
    if (availabilities[index].nightsAllowed?.length) {// if the number of nights explicitly set in the sheet
      duration = availabilities[index].nightsAllowed;
    } else {// otherwise, look at the pricing models for this pricing model Id, and bring all available nights
      duration = pricingModels.filter((element) => element.pricingModelId === availabilities[index].pricingModelId).map((element) => element.nightCount);
    }

    // Now, we need to remove those nights elements that go to the next already booked dates
    let maxAllowed = duration[duration.length - 1];
    let nextIndex = index + 2;
    while (
      nextIndex < availabilities.length &&
      availabilities[nextIndex].accomAvailable === "Available" &&
      nextIndex - index < maxAllowed
    ) {
      nextIndex++;
    }
    maxAllowed = nextIndex - index;



    // fill the nights-allowed dropdown
    for (let i = 0; i < duration.length; i++) {
      const element = duration[i];
      if (element > maxAllowed) {
        break;
      }
      $dropdown.append(
        $("<option></option>")
          .attr("value", element)
          .text(`${element} ${terms.nightsLower}`)
      );
    }

    if (
      previousSelectedNights &&
      previousSelectedNights <= maxAllowed &&
      previousSelectedNights >= duration[0]
    ) {
      $dropdown.val(previousSelectedNights);
    }

    setPrice(calculatePrice());
  };

  const prepareDatepicker = (property) => {

    return new Promise((resolve) => {
      if (!property) {// if no chalet is selected
        resolve();
        return;
      }

      // download availability sheet for the selected chalet
      getAvailabilityFor(property)
        .then((availabilities) => {

          // initialize datepicker if it is not already
          if (!$datepicker) {
            $datepicker = $("#datepicker").datepicker({
              showOn: "both",
              buttonText: "<i class='far fa-calendar-alt fa-lg'></i>",
              dateFormat: "dd M yy",
            });
          }

          // change datepicker language to French
          if (isFrench)
            $.datepicker.setDefaults($.datepicker.regional["fr"]);


          // register an action for when the selected date changes
          $datepicker.datepicker("option", "onSelect", function (dateText, instance) {
            selectedDateChanged(
              instance?.selectedYear,
              instance?.selectedMonth,
              instance?.selectedDay,
              availabilities
            );
          });


          $datepicker.datepicker(
            "option",
            "minDate",
            new Date(availabilities[0].date)
          );
          $datepicker.datepicker(
            "option",
            "maxDate",
            new Date(availabilities[availabilities.length - 1].date)
          );

          // set the default date to appear on the screen be the first available date
          const firstAvailableDate = getFirstAvailableDate(availabilities);
          //$datepicker.datepicker("setDate", firstAvailableDate);
          //selectedDateChanged(firstAvailableDate?.getFullYear(), firstAvailableDate?.getMonth(), firstAvailableDate?.getDate(), availabilities);
          $datepicker.datepicker("option", "defaultDate", firstAvailableDate);

          // register an action for when each date square (cell) appears in datepicker, we style that square
          $datepicker.datepicker("option", "beforeShowDay", function (date) {

            if (date < $datepicker.datepicker("option", "minDate") || date > $datepicker.datepicker("option", "maxDate")) {// if date is not in the range
              // make that cell disabled
              return [false, ""];
            }

            const now = new Date();
            now.setHours(0, 0, 0, 0);
            if (date <= now) {// if date is in past, show as booked
              return [false, "booked"];
            }

            // for each date in the sheet we associate a number (dateValue) which represents that date, this is easier for sorting and searching
            let dateValue =
              Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              ).valueOf() / Math.pow(10, 5);

            // find the availability record for the date in this particular cell (square)
            let index = binarySearchDateAvailability(
              availabilities,
              dateValue,
              0,
              availabilities.length - 1
            );

            let output = [false, ""];

            if (index === -1) {
              return output;
            }

            // if the availability is for another season, the cell is clickable but has a special class, and a tooltip
            if (isSummer && availabilities[index].season === "Winter") {
              return [true, "is-winter-date", terms.checkOurWinterWebsite];
            } else if (!isSummer && availabilities[index].season === "Summer") {
              return [true, "is-winter-date", terms.checkOurSummerWebsite];
            }





            switch (availabilities[index].accomAvailable) {
              case "Booked":
                output = [false, "booked"];
                break;
              case "AlpineLodgeOnly":
              case "AlpineLoftOnly":
                output = [false, "partially-available"];
                break;
              case "Available":
                output = [false, ""];// we change it if fulfills the other condition in lines below
                break;
              default:
                console.error(
                  terms.availabilityNotDefined + availabilities[index].accomAvailable
                );
                return output;
            }

            if (
              availabilities[index].arrivalsAllowed && // can arrive this date
              index + 1 < availabilities.length && // this is not the last date in the range
              availabilities[index + 1].accomAvailable === "Available" // and next day is also available, so the holiday must be at least 2 nights
            ) {
              output[0] = true;
              output[1] += " arrivals-allowed";
            }

            return output;
          });

          let selectedDate = $datepicker.datepicker("getDate");
          if (selectedDate) {
            selectedDateChanged(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), availabilities);
          }
          resolve();
        });

    });
  };

  const binarySearchDateAvailability = (arr, x, start, end) => {
    // Base Condition
    if (start > end) return -1;

    // Find the middle index
    let mid = Math.floor((start + end) / 2);

    // Compare mid with given key x
    if (arr[mid].dateValue === x) return mid;

    // If element at mid is greater than x,
    // search in the left half of mid
    if (arr[mid].dateValue > x)
      return binarySearchDateAvailability(arr, x, start, mid - 1);
    // If element at mid is smaller than x,
    // search in the right half of mid
    else return binarySearchDateAvailability(arr, x, mid + 1, end);
  };

  const setPAX = (property) => {
    if (!property) {// if no chalet is selected
      $("#pMaxPax").hide();
      return false;
    } else {
      let maxPax;

      // find max pax for the selected chalet
      if (getAnnexCheckbox().is(":checked")) {
        maxPax = getSelectedChaletItem().data("max-pax-with-annex") ?? getSelectedChaletItem().data("max-pax");
      } else {
        maxPax = getSelectedChaletItem().data("max-pax");
      }

      if (!maxPax) {
        console.error(`The max pax is not set for ${property}`);
        return false;
      }

      $("#sMaxPax").text(maxPax);
      $("#pMaxPax").show();
      return true;
    }
  };

  const setCleaningCheckBoxSelectivity = () => {
    const $cleaningLi = getCleaningLi();
    if (getCateringCheckbox().is(":checked")) {
      $cleaningLi
        .addClass("pre-selected");
      if (!getCleaningCheckbox().is(":checked")) {
        $cleaningLi.click();
      }
    } else {
      $cleaningLi
        .removeClass("pre-selected");
    }
  };

  const setAnnexCheckBoxSelectivity = (radioValue) => {
    const $annexLi = getAnnexLi();

    // if the select chalet is combination, set annex check box pre selected, and change the term for checkbox too 
    if (radioValue === "CLG_AlpineCombination") {
      $annexLi
        .addClass("pre-selected")
        .find("label")
        .text(terms.annexRooms);
      if (!getAnnexCheckbox().is(":checked")) {
        $annexLi.click();
      }
    } else {
      $annexLi.removeClass("pre-selected").find("label").text(terms.annexRoom);
    }
  };

  // show the already calculated price for user
  const setPrice = (promise) => {

    // a local procedure will be called when the price can't be calculated
    const setNull = () => {
      $("#pTotalPrice").hide();
      $("#BookNowCTA").prop("disabled", true);
      $("#BookNowCTA").prop("title", terms.enterYourHolidayDetails);
      calculatedPrice = null;
      selectedPricingModel = null;
    };

    // there is no price calculated
    if (!promise) {
      setNull();
      return;
    }

    promise.then((e) => {
      if (e == null) {// there is no price calculated
        setNull();
      } else {
        // show the price for user

        selectedPricingModel = e.pricingModel;
        $("#sTotalPrice").text(
          e.price.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );
        if (e.specialOffer) {// can we apply special offer
          calculatedPrice = e.price * (1 - e.specialOffer.discountPercentage);
          $("#pTotalPrice span.totalPrice").addClass("discounted");
          $("#sTotalPriceAfterDiscount").show().children('span').text(calculatedPrice.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }));
          $("#sDiscountText").show().text(isFrench ? e.specialOffer.textForWebsiteFR : e.specialOffer.textForWebsiteEN);
        } else {
          calculatedPrice = e.price;
          $("#pTotalPrice span.totalPrice").removeClass("discounted");
          $("#sTotalPriceAfterDiscount").hide().children('span').text("");
          $("#sDiscountText").hide().text("");
        }

        $("#pTotalPrice").show();
        $("#BookNowCTA").prop("disabled", false);
        $("#BookNowCTA").removeAttr("title");
      }
    });
  };

  const setAnnexThings = (pricingModel, flexiEffect) => {
    const $annexLi = getAnnexLi();
    const $annexLiBookingConfirmation = $('[data-content-name="annexBooked"').closest('li');
    const $annexConfirmationEmail = $('#dvAnnexInConfirmationEmail');

    // if (i) max pax with annex is not set, or (ii) there is no price for annex then we need to hide annex checkbox unless the selected chalet is Alpine Combination
    if ((getSelectedChaletItem().data("max-pax-with-annex") == null || (pricingModel && !pricingModel.annexPrice)) && getSelectedChaletName() !== "CLG_AlpineCombination") {
      $annexLi.hide();
      $annexLiBookingConfirmation.hide();
      $annexConfirmationEmail.html("");
      if (getAnnexCheckbox().is(":checked")) {
        $annexLi.click();
      }
    } else { // otherwise, show the annex checkbox and take into account the annex price if checked
      $annexLi.show();
      $annexLiBookingConfirmation.show();
      $annexConfirmationEmail.html($annexConfirmationEmail.data("annex-hidden-field"));

      if (pricingModel) {

        // Show for the user what is the annex price
        $("#spnAnnexPrice").html(`+ €${(pricingModel.annexPrice * flexiEffect).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`);

        if (getSelectedChaletName() !== "CLG_AlpineCombination") {
          if (getAnnexCheckbox().is(":checked"))
            return pricingModel.annexPrice;
        } else {
          $("#spnAnnexPrice").html(``);
        }
      }
    }
    return 0;
  }

  const calculatePrice = () => {

    return new Promise((resolve) => {
      $('#addOnsSelectionList .optionalPricingDetail').html('');// reset price details

      // set annex box visibility
      setAnnexThings(null, null);


      const selectedProperty = getSelectedChaletName();
      if (!setPAX(selectedProperty)) {
        resolve(null);
        return;
      }

      const selectedDate = $datepicker?.datepicker("getDate");
      if (!selectedDate) {
        resolve(null);
        return;
      }

      let selectedNights = getNightsDropdown().val();
      if (!selectedNights) {
        resolve(null);
        return;
      }
      selectedNights = parseInt(selectedNights);
      if (isNaN(selectedNights)) {
        console.error("Couldn't parse this nights length as a number");
        resolve(null);
        return;
      }


      // for the selected chalet we get the availability sheet
      getAvailabilityFor(selectedProperty)
        .then((availabilities) => {

          // for each date in the sheet we associate a number (dateValue) which represents that date, this is easier for sorting and searching

          // here we calculate the date-value of the date selected in datepicker
          let dateValue =
            Date.UTC(
              selectedDate.getFullYear(),
              selectedDate.getMonth(),
              selectedDate.getDate()
            ).valueOf() / Math.pow(10, 5);

          // we use the date-value of the selected date to search in availabilities and get the availability record for that date
          let index = binarySearchDateAvailability(
            availabilities,
            dateValue,
            0,
            availabilities.length - 1
          );

          // if we can't find an availability record for that date, normally this should never happen
          if (index === -1) {
            console.error("Couldn't find this date in file: " + selectedDate);
            resolve(null);
            return;
          }

          // get the pricing model Id
          const pricingModelId = availabilities[index].pricingModelId;
          if (!pricingModelId) {
            console.error("The pricing model Id is invalid: " + pricingModelId);
            resolve(null);
            return;
          }

          // get the details of pricing model
          const pricingModel = pricingModels.find(
            (element) =>
              element.pricingModelId === pricingModelId &&
              element.nightCount === selectedNights
          );
          if (!pricingModel) {
            console.error(
              "Couldn't find the pricing model. Id = " +
              pricingModelId +
              ", night count = " +
              selectedNights
            );
            resolve(null);
            return;
          }

          // we will multiply the final price by this factor, it depends on FlexiPlus checkbox!
          const flexiEffect = getFlexiPlusCheckbox().is(":checked") ? 1 : 0.9;

          // start the base price with accom price
          let price = pricingModel.accomPrice;


          if (pricingModel.cateringPrice) {
            // Show for the user what is the price of catering
            $("#spnCateringPrice").html(`+ €${(pricingModel.cateringPrice * flexiEffect).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`);
            getCateringLi().show();
          } else {
            getCateringLi().hide();
            if (getCateringCheckbox().is(":checked")) {
              getCateringLi().click();
            }
          }

          if (pricingModel.cleaningPrice) {
            // Show for the user what is the price of cleaning
            $("#spnCleaningPrice").html(`+ €${(pricingModel.cleaningPrice * flexiEffect).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`);
            getCleaningLi().show();
          } else {
            $("#spnCleaningPrice").hide();
            getCleaningLi().hide();
            if (getCleaningCheckbox().is(":checked")) {
              getCleaningLi().click();
            }
          }

          if (getCateringCheckbox().is(":checked")) {
            price += pricingModel.cateringPrice;

            // clear what you have shown for cleaning price to user, if catering is checked
            $("#spnCleaningPrice").html(``);

          } else if (getCleaningCheckbox().is(":checked")) {
            price += pricingModel.cleaningPrice;
          }


          price += setAnnexThings(pricingModel, flexiEffect);


          price = price * flexiEffect;// decrease price 10% if FlexiPlus not ticked

          // check if we find and special offer
          const specialOffer = specialOffers.find(so =>
            (!so.lengthInNights.length || so.lengthInNights.some(lng => lng === selectedNights)) && // length in nights within the list or list is empty (applies on any length of nights)
            (!so.chalets.length || so.chalets.some(chl => chl === getSelectedChaletName())) && // selected chalet is in list or list is empty (applies on all chalets)
            new Date(so.bookingDateFrom) <= new Date() && new Date() <= new Date(so.bookingDateTo) && // today is within applicable booking date range
            new Date(so.holidayStartDateFrom) <= selectedDate && selectedDate <= new Date(so.holidayStartDateTo)
          );


          resolve({ price, pricingModel, specialOffer });
        });
    });

  };

  const setContentValueForBookingForm = ($bookingForm, name, value) => {
    $bookingForm.find("[data-content-name=" + name + "]").text(value);
    $bookingForm.find("[data-value-name=" + name + "]").val(value);
  };


  const initializePage = () => {

    // register an action for when selected chalet changes
    getChaletSelector().change(function () {

      // when the selected chalet changes, we need to reset date picker accordingly
      prepareDatepicker(this.value)
        .then(() => {

          // when date picker set, we need to see if annex check box is selectable or not, as it depends on the chalet and date
          setAnnexCheckBoxSelectivity(this.value);

          // now we might be able to show the price
          setPrice(calculatePrice());
        })
        ;
    });

    // register an action for when any of add-on selection changes
    $("#addOnsSelectionList :checkbox").change(function () {

      // if the change is from the catering check box, we need to enable/disable the cleaning check box
      if (this.id === "chkCatering") {
        setCleaningCheckBoxSelectivity();
      }

      // now we might be able to show the price
      setPrice(calculatePrice());
    });

    // register an action for when the number-of-nights dropdown changes  
    getNightsDropdown().change(function () {
      // we might be able to show the price
      setPrice(calculatePrice());
    });

    // register an action for when an li which contains a chalet is clicked 
    $("#propertySelectionList li").click(function () {
      let $li = $(this);

      // remove the selection from the chalet which was selected before 
      $li.siblings("li").removeClass("selected");

      // if the previous selected chalet is not this chalet, now select it
      if (!$li.hasClass("selected")) {
        $li.addClass("selected");

        // set the checked attr for the checkbox inside li
        getSelectedChaletItem().prop("checked", false);

        // fire the event that the checkbox checked
        $li.find("input").prop("checked", true).change();
      }

      return false; // this stops propagation
    });

    // register an action for when an li which contains an add-on is clicked
    $("#addOnsSelectionList li").click(function () {
      let $li = $(this);

      // if it is not selected, select it
      if (!$li.hasClass("selected")) {

        $li.addClass("selected");

        // fire the event that the checkbox inside li is checked
        $li.find("input").prop("checked", true).change();

      } else {// if it is selected, now unselect it

        $li.removeClass("selected");

        // fire the event that the checkbox inside li is unchecked
        $li.find("input").prop("checked", false).change();
      }
      return false; // this stops propagation
    });

    const radioValue = getSelectedChaletName();

    // initialize and prepare the datepicker element
    prepareDatepicker(radioValue)
      .then(() => {
        //when datepicker is initialized, do the following

        setAnnexCheckBoxSelectivity(radioValue);

        setCleaningCheckBoxSelectivity();

        setPrice(calculatePrice());

        // register an action for when the Book Now button is clicked
        $("#BookNowCTA").click(function () {
          $("#dvAvailabilities").hide();
          const $bookingForm = $("#dvBookingForm");

          $bookingForm.show();

          setContentValueForBookingForm(
            $bookingForm,
            "accomNameFormatted",
            getSelectedChaletItem().data("name-formatted")
          );
          setContentValueForBookingForm(
            $bookingForm,
            "arrivalDateFormatted",
            $datepicker.val()
          );
          setContentValueForBookingForm(
            $bookingForm,
            "holidayLengthFormatted",
            getNightsDropdown().val() + ` ${terms.nights}`
          );
          setContentValueForBookingForm(
            $bookingForm,
            "priceValue",
            "€" +
            calculatedPrice.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          );
          setContentValueForBookingForm(
            $bookingForm,
            "paxNumber",
            $("#sMaxPax").text()
          );
          setContentValueForBookingForm(
            $bookingForm,
            "flexiPlusPolicy",
            getFlexiPlusCheckbox().is(":checked")
              ? terms.included
              : terms.notIncluded
          );
          setContentValueForBookingForm(
            $bookingForm,
            "annexBooked",
            getAnnexCheckbox().is(":checked")
              ? terms.included
              : terms.notIncluded
          );
          if (getCateringCheckbox().length) {
            setContentValueForBookingForm(
              $bookingForm,
              "catering",
              getCateringCheckbox().is(":checked")
                ? terms.cateringFunc()
                : terms.cateringIsNotIncluded
            );
          }
          if (getCleaningCheckbox().length) {
            setContentValueForBookingForm(
              $bookingForm,
              "cleaning",
              getCleaningCheckbox().is(":checked")
                ? terms.endOfStayInc
                : terms.endOfStayEx
            );
          }

          //BenMojtaba To Do
          //this scrolls to top of page, not to top of booking form. Fix would be nice if not too hard to do.
          //window.scrollTo(0, $("#dvBookingForm").position().top);
        });

        // register an action for when the Back button is clicked
        $("#btnBack").click(function () {
          $("#dvAvailabilities").show();
          $("#dvBookingForm").hide();
        });

        $("#dvLoading").hide();
        $("#dvAvailabilities").show();
      })
      ;

  }

  // First thing is to download pricing models and special offers
  $.when(getJsonDataFor("pricing-model"), getJsonDataFor("special-offers"))
    .then((pm, sof) => {
      // set pricing models to a global variable
      pricingModels = pm;

      specialOffers = sof;

      initializePage();

    })
    .catch(() => {
      $("#dvLoading").hide();
    });

};

$().ready(function () {
  bookingScope();
});
